import http from "@/common/js/http";

/**
 * 获取系统车型列表
 */
export const getCarBrandListApi = async (form) => {
    return await http(`/galaxyCarBrand/getCarBrandList`, form, 'POST');
};


/**
 * 删除系统车型列表
 */
export const deleteCarBrandApi = async (form) => {
    return await http(`/galaxyCarBrand/deleteCarBrand`, form, 'POST');
};


/**
 * 新增系统车型列表
 */
export const addCarBrandApi = async (form) => {
    return await http(`/galaxyCarBrand/addCarBrand`, form, 'POST');
};

/**
 * 更新系统车型列表
 */
export const updateCarBrandApi = async (form) => {
    return await http(`/galaxyCarBrand/updateCarBrand`, form, 'POST');
};

/**
 * 获取携程车型列表
 */
export const getCtripCarBrandListApi = async (form) => {
    return await http(`/galaxyCarBrand/getCtripCarBrandList`, form, 'POST');
};


/**
 * 删除携程车型列表
 */
export const deleteCtripCarBrandApi = async (form) => {
    return await http(`/galaxyCarBrand/deleteCtripCarBrand`, form, 'POST');
};


/**
 * 新增系统车型列表
 */
export const addCtripCarBrandApi = async (form) => {
    return await http(`/galaxyCarBrand/addCtripCarBrand`, form, 'POST');
};

/**
 * 更新系统车型列表
 */
export const updateCtripCarBrandApi = async (form) => {
    return await http(`/galaxyCarBrand/updateCtripCarBrand`, form, 'POST');
};

