<template>
    <el-main class="com-eTrip-section">
        <el-tabs v-model="tabIndex">
            <el-tab-pane label="900车型管理" name="900">
                <ETripModelMange></ETripModelMange>
            </el-tab-pane>
            <el-tab-pane label="携程车型管理" name="xiecheng">
                <CTripModelMange></CTripModelMange>
            </el-tab-pane>
        </el-tabs>
    </el-main>
</template>

<script>
import ETripModelMange from './components/ETripModelMange'
import CTripModelMange from './components/CTripModelMange'

export default {
    name: "ModelManage",
    data() {
        return {
            tabIndex: "900"
        }
    },
    components: {
        ETripModelMange,
        CTripModelMange
    }
}
</script>

<style scoped>

</style>
