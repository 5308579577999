<template>
    <el-dialog :visible.sync="visible" :title="title">
        <el-form ref="form" :model="form" :rules="rules" size="medium">
            <el-form-item label="车辆品牌" prop="carBrand">
                <el-input placeholder="请输入车辆品牌" clearable v-model="form.carBrand"/>
            </el-form-item>
            <el-form-item label="品牌英文首字符" prop="carBrandEnglish">
                <el-input placeholder="请输入品牌英文首字符" :maxlength="1" clearable v-model="form.carBrandEnglish"/>
            </el-form-item>
            <el-form-item label="车辆型号名称" prop="carModel">
                <el-input placeholder="请输入车辆型号名称" clearable v-model="form.carModel"/>
            </el-form-item>
            <el-form-item label="车辆型号" prop="carType">
                <el-select filterable style="width: 100%;" clearable v-model="form.carType">
                    <el-option v-for="vm in seatModelList" :label="vm.name" :value="vm.value"
                               :key="vm.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <div class="buttons">
                    <el-button type="primary" plain @click="visible=false" loading:="loading">取消</el-button>
                    <el-button type="primary" @click="handleSubmit" :loading="loading">确定</el-button>
                </div>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import {seatModelList} from '@/data/index'
import {addCarBrandApi, updateCarBrandApi} from "@/www/urls/modelMange";

const createForm = () => ({
    carBrand: '',
    carModel: '',
    carBrandEnglish: '',
    carType: ''
})

export default {
    name: "AddETripModel",
    data() {
        return {
            loading: false,
            visible: false,
            form: {
                carBrand: '',
                carModel: '',
                carBrandEnglish: '',
                carType: ''
            },
            seatModelList,
            rules: {
                carBrand: [{required: true, message: "请输入车辆品牌"}],
                carBrandEnglish: [{required: true, message: "请输入车辆品牌英文首字符"}],
                carModel: [{required: true, message: "请输入车辆型号名称"}],
                carType: [{required: true, message: "请输入选择车辆型号"}],
            }
        }
    },
    computed: {
        title() {
            return (this.form.id ? '编辑' : '新增') + '900车型'
        }
    },
    methods: {
        open(info) {
            this.visible = true;
            this.form = createForm();
            if (info)
                this.form = {
                    ...info
                }
        },
        async handleSubmit() {
            try {
                await this.$refs['form'].validate();
                this.loading = true;
                let ret;
                if (this.form.id)
                    ret = await updateCarBrandApi(this.form)
                else
                    ret = await addCarBrandApi(this.form)
                if (ret.success) {
                    this.$message.success((this.form.id ? '编辑' : '新增') + '成功～');
                    this.visible = false;
                    this.$emit('success', !!this.form.id)
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style scoped>
.buttons {
    display: flex;
    justify-content: flex-end;
}
</style>
