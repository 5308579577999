<template>
    <div>
        <el-form inline size="medium">
            <el-form-item label="携程车系名称">
                <el-input v-model="form.vehicleGroupName" clearable placeholder="请输入携程车系名称"></el-input>
            </el-form-item>
            <el-form-item label="携程车型名称">
                <el-input v-model="form.vehicleName" clearable placeholder="请输入携程车型名称"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="reset">重置</el-button>
                <el-button type="primary" @click="handleSearch">搜索</el-button>
                <el-button type="success" @click="add()">新增</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" height="calc(100vh - 295px)" v-loading="loading">
            <el-table-column type="index" align="center" label="序号" width="90"></el-table-column>
            <el-table-column prop="vehicleGroupName" label="携程车系名称" align="center"></el-table-column>
            <el-table-column prop="vehicleName" align="center" label="携程车型名称"></el-table-column>
            <el-table-column align="center" label="车型">
                <template slot-scope="scope">
                    {{ formatCarModel(scope.row.vehicleGroupId).name }}
                </template>
            </el-table-column>
            <el-table-column label="创建时间" align="center">
                <template slot-scope="scope">
                    {{ scope.row.createTime | dateCus }}
                </template>
            </el-table-column>
            <el-table-column align="center">
                <template slot-scope="scope">
                    <el-button type="warning" size="small" @click="add(scope.row)">编辑</el-button>
                    <el-popconfirm title="确定删除该车型？" @confirm="handleDelete(scope.row)">
                        <el-button type="danger" size="small" class="ml10" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-ct">
            <el-pagination background layout="prev, pager, next" :total="total"
                           :page-size="form.pageSize"
                           :current-page="form.pageIndex"
                           @current-change="handlePageChange"></el-pagination>
        </div>
        <AddCTripModel ref="addCTripModel" @success="handleEditSuccess"></AddCTripModel>
    </div>
</template>

<script>
import {deleteCtripCarBrandApi, getCtripCarBrandListApi} from "@/www/urls/modelMange";
import AddCTripModel from './AddCTripModel'
import {getSeatModel} from "@/data";

export default {
    name: "CTripModelMange",
    data() {
        return {
            loading: false,
            list: [],
            total: 0,
            form: {
                vehicleName: undefined,
                vehicleGroupName: undefined,
                pageIndex: 1,
                pageSize: 10
            }
        }
    },
    mounted() {
        this.getData();
    },
    components: {
        AddCTripModel
    },
    methods: {
        async getData() {
            try {
                this.loading = true;
                const form = {
                    ...this.form,
                    vehicleGroupName: this.form.vehicleGroupName || undefined,
                    vehicleName: this.form.vehicleName || undefined,
                }
                const ret = await getCtripCarBrandListApi(form);
                if (ret.success && ret.data) {
                    this.list = ret.data.data;
                    this.total = ret.data.total;
                }
            } finally {
                this.loading = false
            }
        },
        handlePageChange(index) {
            this.form.pageIndex = index;
            this.getData();
        },
        reset() {
            this.form.vehicleGroupName = undefined;
            this.form.vehicleName = undefined;
            this.form.pageIndex = 1;
            this.getData();
        },
        handleSearch() {
            this.form.pageIndex = 1;
            this.getData();
        },
        add(info) {
            this.$refs['addCTripModel'].open(info)
        },
        async handleDelete(info) {
            try {
                this.loading = true
                const ret = await deleteCtripCarBrandApi({
                    id: info.id
                })
                if (ret.success) {
                    this.$message.success("删除成功～")
                    this.getData();
                } else
                    this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false;
            }
        },
        handleEditSuccess(bool) {
            if (!bool)
                this.form.pageIndex = 1;
            this.getData();
        },
        formatCarModel(type) {
            let info = {};
            if (type) {
                info = getSeatModel(parseInt(type));
            }
            return info;
        },
    }
}
</script>

<style scoped lang="scss">
.pagination-ct {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
}

</style>
