<template>
    <el-dialog :visible.sync="visible" :title="title">
        <el-form ref="form" :model="form" :rules="rules" size="medium">
            <el-form-item label="携程车系名称" prop="vehicleGroupName">
                <el-input placeholder="请输入携程车系名称" clearable v-model="form.vehicleGroupName"/>
            </el-form-item>
            <el-form-item label="携程车型名称" prop="vehicleName">
                <el-input placeholder="请输入携程车型名称" clearable v-model="form.vehicleName"/>
            </el-form-item>
            <el-form-item label="车辆型号" prop="vehicleGroupId">
                <el-select filterable style="width: 100%;" clearable v-model="form.vehicleGroupId">
                    <el-option v-for="vm in seatModelList" :label="vm.name" :value="vm.value"
                               :key="vm.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <div class="buttons">
                    <el-button type="primary" plain @click="visible=false" loading:="loading">取消</el-button>
                    <el-button type="primary" @click="handleSubmit" :loading="loading">确定</el-button>
                </div>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import {seatModelList} from '@/data/index'
import {addCtripCarBrandApi, updateCtripCarBrandApi} from "@/www/urls/modelMange";

const createForm = () => ({
    vehicleName: '',
    vehicleGroupName: '',
    vehicleGroupId: ''
})

export default {
    name: "AddCTripModel",
    data() {
        return {
            loading: false,
            visible: false,
            form: {
                vehicleName: '',
                vehicleGroupName: '',
                vehicleGroupId: ''
            },
            seatModelList,
            rules: {
                vehicleName: [{required: true, message: "请输入携程车型名称"}],
                vehicleGroupName: [{required: true, message: "请输入携程车系名称"}],
                vehicleGroupId: [{required: true, message: "请输入选择车辆型号"}],
            }
        }
    },
    computed: {
        title() {
            return (this.form.id ? '编辑' : '新增') + '携程车型'
        }
    },
    methods: {
        open(info) {
            this.visible = true;
            this.form = createForm();
            if (info)
                this.form = {
                    ...info
                }
        },
        async handleSubmit() {
            try {
                await this.$refs['form'].validate();
                this.loading = true;
                let ret;
                if (this.form.id)
                    ret = await updateCtripCarBrandApi(this.form)
                else
                    ret = await addCtripCarBrandApi(this.form)
                if (ret.success) {
                    this.$message.success((this.form.id ? '编辑' : '新增') + '成功～');
                    this.visible = false;
                    this.$emit('success', !!this.form.id)
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style scoped>
.buttons {
    display: flex;
    justify-content: flex-end;
}
</style>
